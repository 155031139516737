import React, { useEffect } from 'react';
import '../style/ProjectModal.css';

const ProjectModal = ({ project, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="modal-title">{project.name || 'Untitled Project'}</h2>
          <button onClick={onClose} className="close-button" aria-label="Close">
            ×
          </button>
        </div>
        <div className="modal-body">
          {project.video && (
            <iframe 
              width="100%" 
              src={project.video} 
              title={project.name} 
              className="project-video"
              allowFullScreen
            ></iframe>
          )}
          {project.github && (
            <h3 className="github-link">
              <a href={project.github} target="_blank" rel="noopener noreferrer">GitHub</a>
            </h3>
          )}
          {project.tools && (
            <h3 className="project-tools"> Tools Used:
              <p>{project.tools}</p>
            </h3>
          )}
          {project.description && (
            <div className="project-description">
              <p>{project.description}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectModal;