import React from 'react';
import '../style/ProjectCard.css';

const ProjectCard = ({ project, onClick }) => (

    <div className="container">
        <div className="project-card" onClick={onClick}>
            <img src={project.image} alt={project.name} className="project-image" />
            <h3 className="project-name">{project.name}</h3>
        </div>
    </div>

);

export default ProjectCard;