import React from 'react';
import '../style/About.css';

function About() {
    return (
        <div className="about-wrapper">
            <div className="about-container">
                <div className="about-content">
                    <p>In 2017, I discovered my passion for technology when I enrolled in an introductory Computer Engineering class during high school. Fast forward some years, and I am now a recent graduate of Computer Engineering from Toronto Metropolitan University, specializing in Software Engineering.</p>
                    <p>My journey continues as I actively enhance my skills through projects. My current goal is to become a proficient/good Software Developer. To see my current projects and progress, check out the Projects section.</p>
                    <p>When I'm not immersed in my laptop, you'll often find me in the gym, experimenting in the kitchen, baking delicious treats, or enjoying quality time with friends.</p>
                </div>
            </div>
        </div>
    );
}

export default About;
