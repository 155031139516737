import React from 'react';
import '../style/Header.css';

function Header({ setCurrentSection }) {
    return (
        <header className="header">
            <nav className="nav">
                <ul className="nav-list">
                    <li className="nav-item" onClick={() => setCurrentSection('about')}>ABOUT</li>
                    <li className="nav-item" onClick={() => setCurrentSection('projects')}>PROJECTS</li>
                    <li className="nav-item" onClick={() => setCurrentSection('resume')}>RESUME</li>
                    <li className="nav-item" onClick={() => setCurrentSection('contact')}>CONTACT</li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;
