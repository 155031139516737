import React, { useState } from 'react';
import Header from './components/Header';
import About from './components/About';
import MyProjects from './components/MyProjects';
import Resume from './components/Resume';
import Contact from './components/Contact';
import './App.css';

function App() {
    const [currentSection, setCurrentSection] = useState('home');

    const renderSection = () => {
        switch (currentSection) {
            case 'projects':
                return <MyProjects />;
            case 'resume':
                return <Resume />;
            case 'contact':
                return <Contact />;
            default:
              return <About />;
        }
    };

    return (
        <div className="App">
            <Header setCurrentSection={setCurrentSection} />
            {renderSection()}
        </div>
    );
}

export default App;
