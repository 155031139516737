import React, { useState } from 'react';
import ProjectCard from './ProjectCard';
import ProjectModal from './ProjectModal';
import '../style/MyProjects.css';
const MusicTrivia_PNG = require('../assets/MusicTrivia.png');
const FastAPI_PNG = require('../assets/FastAPI.PNG');
const Spotify_PNG = require('../assets/Spotify.PNG');
const Banking_PNG = require('../assets/Banking.PNG');
const Book_Management_PNG = require('../assets/Book_Management.png');
const Dating_PNG = require('../assets/Dating.png');
const P2P_PNG = require('../assets/P2P.png');

const projectsData = [
    // {
    //     name: "",
    //     image: image1,
    //     video: "https://www.youtube.com/embed/TcWUQnB_nxE",
    //     github: "",
    //     tools: "",
    //     description: ""
    // },
    {
        name: "Multiplayer Music Trivia",
        image: MusicTrivia_PNG,
        video: "https://www.youtube.com/embed/x_2FKTnaY50",
        github: "https://github.com/MohamadAlkahil/Music_Trivia_Spring",
        tools: "Java, Spring Boot, Spring WebSocket, Spring Security, JSON Web Tokens, OpenTriviaDB API, JavaScript, React, SockJS, STOMP, CSS",
        description: "This project is a real-time multiplayer music trivia game built using Java Spring Boot for the backend and React for the front end. The application utilizes WebSocket communication for real-time interactions between players. Users can create or join game sessions, select avatars, and participate in music-related trivia questions. The game has user authentication, session management, real-time score tracking, and a lobby system where players can wait before starting a game."
    },
    {
        name: "Spotify Top Items",
        image: Spotify_PNG,
        video: "https://www.youtube.com/embed/ucXTCrL_bo8",
        github: "https://github.com/MohamadAlkahil/Spotify_Top",
        tools: "Javascript, HTML, CSS, React.js, Node.js, Express.js, Spotify API",
        description: "This web application was built using React that interacts with the Spotify API to fetch and display a users top artists, and top tracks."
    },
    {
        name: "FastAPI Application",
        image: FastAPI_PNG,
        video: "https://www.youtube.com/embed/TcWUQnB_nxE",
        github: "https://github.com/MohamadAlkahil/FastAPI_Application/tree/main",
        tools: "Python, FastAPI, Docker, Azure, JSON, Hashlib, PyCharm IDE",
        description: "This Python project simulates rovers navigating a minefield represented as a 2D array, where each cell contains either a mine (non-zero) or is empty (zero). The rover can follow commands like turning left (L), turning right (R), moving forward (M), and digging (D). The code sequentially processes each rover's commands, starting at the initial position (0,0) facing south, and calculates the rover's path on the map. It marks the path with \"*\" symbols and handles scenarios where rovers encounter mines. If a rover doesn't dig a mine and moves, the mine explodes, and the remaining commands are ignored. The project is split into a Server and an Operator. The FastAPI HTTP server provides various endpoints that are to be used by the operator to control the rover. The server is deployed as a local container through Docker and then hosted as a web app on Azure. The operator is a command line interface that takes keyboard inputs from the application's end user to control the rover."
    },
    {
        name: "P2P File Sharing",
        image: P2P_PNG,
        github: "https://github.com/MohamadAlkahil/PeerToPeer_Application",
        tools: "C Programming Language, Linux Virtual Machine, Socket Programming, UDP (User Datagram Protocol), TCP (Transmission Control Protocol), File I/O",
        description: "This project implements a simple peer-to-peer (P2P) network application. It consists of multiple peers that can share content with each other, facilitated by a central index server. Peers can register content, search for content, download files from other peers, and de-register content. The index server maintains a list of all registered content and helps peers locate the content they want to download. Communication between peers and the index server uses UDP, while file transfers between peers use TCP."
    },
    {
        name: "Banking Application",
        image: Banking_PNG,
        video: "https://www.youtube.com/embed/S8i-CzOfWQo",
        github: "https://github.com/MohamadAlkahil/Banking_Application/tree/master",
        tools: "SQLite3, SQL, Netbeans IDE, Java, JavaFX, JavaFX Scene Builder,JDBC (Java Database Connectivity), SHA-256 Hashing",
        description: "This Java-based project is a bank management system. It consists of several classes representing various aspects of the banking system, including actors (customers and managers), account levels, and user interface components. The project primarily focuses on user authentication, customer management, and basic banking operations. The project integrates with an SQLite database to store customer and manager information. It retrieves and updates data from this database as needed for authentication and customer management. SHA256 is used for password hashing to ensure security. The transaction fee for each user is determined based on their account level and is implemented using the state design pattern."
    },
    {
        name: "Book Management System",
        image: Book_Management_PNG,
        video: "https://www.youtube.com/embed/bBUHB5sBBtk",
        github: "https://github.com/MohamadAlkahil/Book_Management_System",
        tools: "SQLite3, SQL, Eclipse IDE, Java, JDBC (Java Database Connectivity)",
        description: "This project is a DBMS for storing and managing information related to users, orders, publishers, books, genres, book-genre associations, and user feedback. It ensures data integrity through primary keys, foreign key constraints, and unique constraints. The schema serves as the foundation for the functionality of the Java-based database query application. The command-line application allows users to retrieve specific information from the database, such as book ratings, author details, book prices, and more, based on their query selections."
    },
    {
        name: "Dating Application Management System",
        image: Dating_PNG,
        github: "https://github.com/MohamadAlkahil/Dating_Management_System/tree/main",
        tools: "sqldeveloper, Oracle SQL, MobaXterm, Linux",
        description: "This project is a database management system for a dating application using Oracle SQL. The database schema is defined through SQL statements, and various shell scripts are provided to interact with the database, including table creation, data population, and querying. The DBMS is designed to handle various aspects of the dating application, including user profiles, messaging, likes, matches, and interactions with outside companies."
    },
];

function MyProjects() {
  const [selectedProject, setSelectedProject] = useState(null);

  const handleProjectClick = (project) => {
    console.log('Selected project:', project);
    setSelectedProject(project);
  };

  return (
    <div className="projects-container">
      <div className="projects-grid">
        {projectsData.map((project, index) => (
          <ProjectCard
            key={index}
            project={project}
            onClick={() => handleProjectClick(project)}
          />
        ))}
      </div>
      {selectedProject && (
        <ProjectModal
          project={selectedProject}
          onClose={() => setSelectedProject(null)}
        />
      )}
    </div>
  );
}

export default MyProjects;