import React from 'react';
import '../style/Resume.css';
import resumePDF from '../assets/MohamadAlkahil_Resume.pdf';

function Resume() {
    return (
        <div className="resume-container">
            <div className="resume-content">
                <iframe
                    src={resumePDF}
                    title="Resume"
                    className="resume-pdf"
                />
            </div>
        </div>
    );
}

export default Resume;