import React from 'react';
import '../style/Contact.css';

function Contact() {
    return (
        <div className="contact-container">
            <div className="contact-content">
                <a href="mailto:malkahil@torontomu.ca" className="contact-item">EMAIL</a>
                <a href="https://www.linkedin.com/in/mohamad-alkahil/" className="contact-item">LINKEDIN</a>
                <a href="https://github.com/MohamadAlkahil" className="contact-item">GITHUB</a>
            </div>
        </div>
    );
}

export default Contact;